import { extend, configure } from "vee-validate";
import {
    required,
    confirmed,
    email,
    numeric,
    min,
    min_value,
    required_if,
    max,
    max_value
} from "vee-validate/dist/rules";
import { i18n } from "./i18n";
import { isAfter, isBefore, isEqual } from "date-fns";
import * as _ from "lodash-es";

configure({
    // this will be used to generate messages.
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(`${field}`);
        return i18n.t(`validations.messages.${values._rule_}`, values);
    },
});

extend("required", {
    ...required,
    // the values param is the placeholders values
    message: (_, values) => i18n.t("validations.messages.required", values),
});

extend("confirmed", {
    ...confirmed,
});

extend("email", {
    ...email,
});

extend("numeric", {
    ...numeric,
});

extend("min", {
    ...min,
});

extend("min_value", {
    ...min_value,
});

extend("max", {
    ...max,
});

extend("max_value", {
    ...max_value,
});

extend("required_if", {
    ...required_if,
});

import CpfValidator from "./validate/cpf";
import EmailOrCpfValidator from "./validate/email-or-cpf";
import FullNameValidator from "./validate/fullname";
import zipcode from "./validate/zipcode";
import date from "./validate/date";
import CnpjValidator from "./validate/cnpj";
import minorityDateValidator from "./validate/minority-date";
import PisValidator from "./validate/pis";
import requiredUnless from "./validate/required-unless";
import nullable from "./validate/nullable";
import CnsValidator from "./validate/cns";
import CustomUrlValidator from "./validate/custom-url";
import CellphoneValidator from "./validate/cellphone";

/**
 * @todo passar para validator function
 */
extend("before-date", {
    params: ["target"],
    validate(value, { target }) {
        if (_.isUndefined(target) || !target) {
            return true;
        }

        //Separa o dia, mês e ano dos campos
        const [startDay, startMonth, startYear] = value.split("/");
        const [endDay, endMonth, endYear] = target.split("/");

        //Valida se a data inicial é menor que a data final
        return (
            isBefore(
                new Date(`${startYear}-${startMonth}-${startDay}`),
                new Date(`${endYear}-${endMonth}-${endDay}`)
            ) ||
            isEqual(
                new Date(`${startYear}-${startMonth}-${startDay}`),
                new Date(`${endYear}-${endMonth}-${endDay}`)
            )
        );
    },
});

/**
 * @todo passar para validator function
 */
extend("after-date", {
    params: ["target"],
    validate(value, { target }) {
        if (target === undefined) {
            return true;
        }
        //Separa o dia, mês e ano dos campos
        const [startDay, startMonth, startYear] = target.split("/");
        const [endDay, endMonth, endYear] = value.split("/");

        //Valida se a data final é maior que a data inicial
        return (
            isAfter(
                new Date(`${endYear}-${endMonth}-${endDay}`),
                new Date(`${startYear}-${startMonth}-${startDay}`)
            ) ||
            isEqual(
                new Date(`${startYear}-${startMonth}-${startDay}`),
                new Date(`${endYear}-${endMonth}-${endDay}`)
            )
        );
    },
});

extend("cpf", CpfValidator);
extend("cnpj", CnpjValidator);
extend("cns", CnsValidator)

extend("pis", PisValidator);
extend("minority-date", minorityDateValidator);

extend("email-or-cpf", {
    validate: EmailOrCpfValidator,
    message: (_, values) => i18n.t("validations.messages.email-or-cpf", values),
});
extend("full-name", FullNameValidator);
extend("zip-code", zipcode);
extend("date", date);

extend("required_unless", {
    ...requiredUnless,
});

extend("nullable", {
    ...nullable,
});

extend("must_exists_if", {
    params: ["field_value", "target_length"],
    validate: (value, { field_value, target_length }) => {
        //fields.find({ name: 'email' });
        if (value == field_value) {
            //console.info("must_exists", value, field_value, target_length, target_length > 0)
            //return true;
            return target_length > 0;
        }
        return true;
    },
});

extend('custom-url', CustomUrlValidator);

extend("password-username-checker", {
    message: (_, values) => i18n.t("validations.messages.password-username-checker", values),
    params: ["target"],
    validate(value) {

        /**
         * Password is invalid
         */
        const is_cpf = CpfValidator(value)
        const is_email = email.validate(value);
        if (is_cpf || is_email) {
            return false;
        }

        /**
         * Password is ok
         */
        return true
    },
});

extend("password-weakness-checker", {
    message: (_, values) => i18n.t("validations.messages.password-weakness-checker", values),
    params: ["target"],
    validate(value) {

        /**
         * Password is strong
         */
        const has_min_length = value.length >= 8;
        const has_numbers = /\d/.test(value);
        const has_special_characters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
        const has_lowercase = /[a-z]/.test(value);
        const has_uppercase = /[A-Z]/.test(value);
        if (has_min_length && has_numbers && has_special_characters && has_lowercase && has_uppercase) {  
            return true;
        }

        /**
         * Password is too weak
         */
        return false
    },
});

extend("cellphone", CellphoneValidator);