export default (value, args) => {

    // Remove all non-numeric characters
    const inputOnlyNumbers = value.replace(/\D/g, "");

    // Regular expression to validate the formats: (##) ####-#### and (##) #####-####
    const regex = /^(\d{2})\s?(\d{4,5})[-]?(\d{4})$/;

    // Check if the number matches the expected pattern
    const isValid = regex.test(inputOnlyNumbers);

    return isValid;
};
