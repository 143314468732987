<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :id="fieldsetId"
            :name="internalVid"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
            class="search-bar-item"
        >
            <input :data-cy="internalTestId + '-proxy'" type="hidden" v-model="internalValue" />

            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>



            <v-select
                ref="select"
                :id="inputId"
                :inputId="inputId"
                :data-cy="internalTestId"
                v-model="internalValue"

                :options="options"
                :class="{'is-invalid' : errors.length > 0}"
                :reduce="reduce"
                :placeholder="internalPlaceholder"
                v-on="$listeners"
                @input="dispatchEvent('change', internalValue)"
                v-bind="$attrs"
                autocomplete="off"
            >
                <!--:dir="$vs.rtl ? 'rtl' : 'ltr'" -->
                <template #no-options="{ search, searching, loading }">
                    <slot name="no-options"
                        v-bind:search="search"
                        v-bind:searching="searching"
                        v-bind:loading="loading"
                    >
                        {{ $t('common.no-results-found') }}
                    </slot>
                </template>

                <!-- <template #option="option">
                    <slot name="option" v-bind:option="option">
                        <span  
                    </slot>
                </template> -->


                <template #selected-option-container="{ option, deselect, multiple, disabled }">
                        <div class="vs__selected d-block" :class="{'max-w-30 text-truncate' : multiple }" >
                            <span size="sm" @click="deselect(option)" v-if="multiple" class="cursor-pointer">
                                <XIcon size="0.9x" />
                            </span>
                            <span class="overflow-x-hidden">
                                {{ option.label }}
                            </span>
                        </div>
                    
                </template>                
                <!--
                    <template #selected-option="option">
                        <slot name="selected-option" v-bind:option="option">
                            <div class="vs__selected text-truncate d-inline-block w-25">{{ option.label }}</div>
                            
                        </slot>
                    </template> 
                -->
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>

    </validation-provider>
</template>

<script>
import FormFieldBase from '../mixins/Base'
import { ValidationProvider } from 'vee-validate'
import { XIcon } from "@vue-hero-icons/outline"

/**
 * Componente que encapsula o vue-select
 * @see https://vue-select.org/
 */
export default {
    name: 'form-field-select',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider,
        XIcon
    },
    props : {
        /**
         * Vetor com as opções de escolha disponíveis
         * @see https://vue-select.org/guide/options.html
         */
        options : {
            type: Array | Object,
            required: false
        },
        reduce : {
            type : Function,
            default(option) {
                return option.value || option;
            }
        }
    },
    data() {
        return {
            internalValue : this.value,
        }
    },
    methods : {
        clearField() {
            this.internalValue = -1;
            //this.$refs.select.clearSelection();
        },
        selectValue(value) {
            this.$nextTick(() => {
                this.$refs.select.select(this.internalValue)
            })
        }
    },
    computed : {
        optionStyle() {
            if (this.isMobile()) {
                return {
                    'max-width' : '30%'
                }
            }
        }
    }
}
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-select
            v-model="inputValue"
            name="field-name"
            :options="options"
        >
        </form-field-select>
        <small>Selecionado: {{ inputValue }}</small>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            inputValue: '',
            options : [
                {
                    label: 'Opção 1',
                    value: 'Value1'
                },
                {
                    label: 'Opção 2',
                    value: 'Value2'
                },
            ]
        };
      }
    }
</script>
```
</docs>
-->
