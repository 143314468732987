<template>
  <div
    class="flex w-full h-100 align-items-center justify-content-center p-4"
    id="page-login"
  >
    <div class="col-md-6 col-lg-6 col-xl-4 px-0">
      <b-card>
        <div class="justify-center items-center">
          <div class="login-container w-full p-2">
            <form @submit.prevent v-if="!requestSent" class="mb-4" autocomplete="off">
              <div class="vx-card__title">
                <h5>{{ $t("user.reset.title") }}</h5>
                <p>{{ $t("user.reset.info") }}</p>
              </div>
              <validation-observer ref="formRules" slim>
                <div>
                  <div @click="showInstructions = true">
                    <form-field-password
                      name="curriculum.sensitive.password"
                      v-model="password"
                      rules="required|password-username-checker|password-weakness-checker|max:64"
                      autocomplete="off"
                      untracked
                      immediate
                    />

                    <form-field-password
                      name="curriculum.sensitive.password_confirmation"
                      v-model="password_confirmation"
                      rules="required|confirmed:curriculum.sensitive.password"
                      untracked
                      autocomplete="off"
                    />
                  </div>

                  <password-strength-checker
                    v-show="showInstructions"
                    :password="password"
                    :password_confirmation="password_confirmation"
                  />
                </div>
                <div>
                  <b-button
                    type="submit"
                    variant="primary"
                    @click="submit()"
                    size="sm"
                    data-cy="login-submit"
                    class="btn-block"
                    pill
                    >{{ $t("user.reset.submit") }}
                  </b-button>

                  <b-button
                    variant="link"
                    @click="$router.push({ name: 'login' })"
                    size="sm"
                    class="border btn-block"
                    pill
                  >
                    {{ $t("user.reset.return_to") }}
                  </b-button>
                </div>
              </validation-observer>
            </form>
            <div v-if="requestSent">
              <div class="vx-card__title">
                <div v-if="!requestError">
                  <h5 class="text-success">
                    {{ $t("user.reseted_password_success.title") }}
                  </h5>
                  <p>{{ $t("user.reseted_password_success.info") }}</p>
                </div>
                <div v-else class="alert alert-danger" role="alert" >
                  <h5>{{ $t("user.reseted_password_error.title") }}</h5>
                  <p>{{ $t("user.reseted_password_error.info") }}</p>
                </div>
              </div>

              <b-button
                variant="link"
                pill
                size="sm"
                class="btn-block border"
                @click="$router.push({ name: 'login' })"
                >{{ $t("user.reset.return_to") }}</b-button
              >
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import * as FormComponents from "@components/forms";
import { usePasswords } from "@state/user/passwords";
import companyMixin from "@state/company";
import PasswordStrengthChecker from "@components/user/PasswordStrengthChecker.vue";

export default {
  mixins: [companyMixin],
  components: {
    PasswordStrengthChecker,
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
  },
  setup() {
    // PEGAR O CAMPO QUE NÃO ESTIVER VAZIO
    const passwords = usePasswords();

    return {
      passwords,
    };
  },
  data() {
    return {
      requestSent: false,
      requestError: false,
      requestErrorMessage: "",
      showPassword: false,
      password: "",
      password_confirmation: "",
      showInstructions: false,
    };
  },
  created() {
    if (!_.isEmpty(this.$route.params.token)) {
      // VERIFY IF THE TOKENS EXISTS, IF ITS MATCHES WITH EMAIL
      /*

            this.$auth.login({
                data: {
                    ct: this.$route.params.hash,
                    type: "token"
                },
                staySignedIn: true,
                redirect: '',
            }).then(() => {
                const user = this.$auth.user();

                let route = {name: 'register2'};
                // get the user and check if he needs to change the password
                //if (user.need_to_change_password) {
                //    route = {name: 'register'};
                //} else {
                    if (!_.isEmpty(this.$route.params.module)) {
                        if (this.$route.params.module == 'express') {
                            route = {name: 'register2'};
                        } else if (this.$route.params.module == 'admissional') {
                            route = {name: 'profile-admissional'};
                        }
                    }
                //}
                // CREATING A TIMEOUT ??
                setTimeout(() => {
                    this.$router.replace(route);
                }, 750);
            }, () => {
                // MAKE A WAY TO SEND THE MESSAGE ON non-auth
                this.$router.push({name: 'home'});
            });
            */
    }
  },
  methods: {
    submit() {
      this.$refs.formRules.validate().then((success) => {
        if (success) {
          this.$root.loading = true;
          // SEND THE FORGOT REQUEST
          this.passwords
            .resetPassword({
              id: this.$route.params.auth_id,
              email: this.$route.query.email,
              token: this.$route.params.token,
              password: this.password,
              password_confirmation: this.password_confirmation,
            })
            .then((response) => {

              // Success response
              if (response.data.success === true) {
                this.requestSent = true;
                this.requestError = false;
              }

              // Warning responses
              if (_.has(response.data, "can_continue") && response.data.can_continue === false) {
                this.requestSent = true;
                this.requestError = true;
              }

            })
            .catch((error) => {
              // Error response
            })
            .finally(() => {
              this.$root.loading = false;
            });
          /*
           */
        }
      });
    },
  },
};
</script>
