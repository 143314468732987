<template>
  <div class="pr-10 mb-5" v-if="!isLoading">
    <changeable-status
      :state="isSaving"
      :counter="changeCounter"
      v-if="showStatus"
      fixed
    />

    <div class="d-flex justify-between align-items-center mb-8">
      <h2 class="text-3xl font-weight-bold">{{ formTitle }}</h2>

      <div class="w-25 text-right">
        <b-progress
          v-if="progress.loaded"
          :max="progress.admissional.max"
          animated
          height="1rem"
        >
          <b-progress-bar
            :value="progress.admissional.current"
            variant="primary"
          >
          </b-progress-bar>
        </b-progress>
        <small class="font-italic d-none d-lg-block mt-1">
          {{$t("admissional.fill")}}

          {{ progress.admissional.current }}/{{ progress.admissional.max }}
          <span class="d-none d-xl-inline">
            (
            {{
              Math.floor(
                (progress.admissional.current / progress.admissional.max) * 100
              )
            }}% )
          </span>
        </small>
      </div>
    </div>

    <!-- <div class="d-flex justify-between align-items-center mb-2">
      <h2 class="profile-page-title">{{ formTitle }}</h2>
      <div class="w-25 text-right">
        <b-progress
          v-if="progress.loaded"
          :max="progress.admissional.max"
          animated
          height="1rem"
        >
          <b-progress-bar
            :value="progress.admissional.current"
            variant="primary"
          >
          </b-progress-bar>
        </b-progress>
        <small class="font-italic d-none d-lg-block mt-1">
          Preenchimento:

          {{ progress.admissional.current }}/{{ progress.admissional.max }}
          <span class="d-none d-xl-inline">
            (
            {{
              Math.floor(
                (progress.admissional.current / progress.admissional.max) * 100
              )
            }}% )
          </span>
        </small>
      </div>

    </div> -->
    <validation-observer ref="profileRules" tag="div" class="">
      <div class="accordion" id="accordionExample">
        <validation-observer
          v-for="(section, index) in formSections"
          ref="profileInnerRules"
          :key="section.name"
          class="accordion-item"
          tag="div"
        >
          <!-- <div v-for="(section, index) in formSections" :key="'collapse-' + index"> -->
          <h2 class="accordion-header application-steps">
            <button
              class="accordion-button"
              type="button"
              aria-expanded="true"
              aria-controls="collapseOne"
              v-b-toggle="collapaseBaseId + '-' + index"
              @click="goToSection(section.name)"
            >
              <div
                class="flex flex-row flex-grow justify-content-between align-items-center"
              >
                <div class="flex align-items-center">
                  <div
                    v-if="
                      sectionProgress(section.name).max !==
                      sectionProgress(section.name).current
                    "
                    class="step-icon invalid-step"
                  >
                    <b-icon icon="x" />
                  </div>
                  <div v-else class="step-icon completed-step">
                    <b-icon icon="check" />
                  </div>
                  <p class="my-0">{{ $t(section.title) }}</p>
                </div>

                <div class="w-15 mr-5 text-right">
                  <b-progress
                    v-if="progress.loaded"
                    :max="sectionProgress(section.name).max"
                    striped
                    height="1rem"
                  >
                    <b-progress-bar
                      :value="sectionProgress(section.name).current"
                      :variant="
                        sectionProgress(section.name).max ==
                        sectionProgress(section.name).current
                          ? 'success'
                          : 'danger'
                      "
                    />
                  </b-progress>

                  <small class="font-italic d-none d-lg-block text-nowrap mt-1">
                    {{$t("admissional.section")}}

                    {{ sectionProgress(section.name).current }}/{{
                      sectionProgress(section.name).max
                    }}
                    <span class="d-none d-xl-inline">
                      (
                      {{
                        Math.floor(
                          (sectionProgress(section.name).current /
                            sectionProgress(section.name).max) *
                            100
                        )
                      }}% )
                    </span>
                  </small>
                </div>
              </div>
            </button>
          </h2>
          <b-collapse
            :id="collapaseBaseId + '-' + index"
            accordion="my-accordion"
            role="tabpanel"
            v-model="collapses[index]"
          >
            <div class="accordion-body">
              <component
                form="admissional"
                v-bind="section"
                :section="section"
                v-bind:is="section.component"
                :ref="section.name"
                :candidate_id="candidate_id"
                :selective_process_id="selective_process_id"
              />
            </div>
          </b-collapse>
          <!-- </div> -->
        </validation-observer>
        <div class="pb-4 pt-4" style="display: grid">
            <b-button variant="primary" class="btn" pill @click="save">
              {{ $t("common.save") }}
            </b-button>
          </div>
      </div>
    </validation-observer>

    <!-- <validation-observer ref="profileRules" tag="div" class="">
            <validation-observer v-for="(section, index) in formSections" ref="profileInnerRules" v-slot="{ invalid }" :key="section.name">
                    <b-card no-body class="mb-1" v-if="section.show">
                        <b-card-header header-tag="header" class="vs-collapse-item--header" role="tab">
                            <div v-b-toggle="section.name" :class="section.name" v-on:click="goToSection(section.name)" slot="header">
                                <b-icon class="error-icon" v-if="invalid" icon="x-circle-fill" />
                                <b-icon class="success-icon" v-else icon="check-circle-fill" />
                                {{ $t(section.title) }}
                            </div>
                        </b-card-header>
                        <b-collapse :id="section.name" accordion="my-accordion" role="tabpanel" v-model="collapses[index]">
                            <b-card-body>
                                <component
                                    v-bind="section"
                                    :section="section"
                                    v-bind:is="section.component"
                                    :ref="section.name"
                                />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </validation-observer>
        </validation-observer> -->

    <!-- <div class="button-div button-save-div">
            <b-button :disabled="hasErrors"
                       class="btn-save"
                       color="primary"
                       type="filled"
                       @click="save($event)"
                       :style="{'background-color': company.config.theme['primary_color'], 'border-color': company.config.theme['primary_color']}"
            >
                {{ $t('common.save') }}
            </b-button>
        </div> -->

    <b-modal
      id="modal-ocr"
      size="md"
      centered
      no-close-on-backdrop
      hide-header-close
      scrollable
      header-class="d-flex justify-content-center flex-row"
    >
      <template #modal-title>
        <div>
          <span v-if="!ocrLoading">
            <ExclamationIcon class="text-primary" size="3x" />
          </span>
          <span v-else>
            <ClockIcon class="text-primary" size="3x" />
          </span>
          <p v-if="!ocrLoading">{{ $t("ocr.error_title") }}</p>
          <p v-else>{{ $t("ocr.await_title") }}</p>
        </div>
      </template>
      <div class="text-center" v-if="ocrLoading">
        <span>
          <b-spinner class="text-primary mb-4" style="opacity: 0.5" />
        </span>
        <p>{{ $t("ocr.await_description") }}</p>
      </div>
      <div v-else>
        <p>{{ $t("ocr.error_description") }}</p>
        <div class="justify-content-center d-flex">
          <ul>
            <li v-for="(field, index) in ocrErrorFields" :key="index">
              <span>{{ $tc("curriculum." + field + ".label") }}</span>
            </li>
          </ul>
        </div>
        <div>
          <p>{{ $t("ocr.error_validate") }}</p>
          <div class="justify-content-center d-flex">
            <ol>
              <li>{{ $t("ocr.error_validate_item_1") }}</li>
              <li>{{ $t("ocr.error_validate_item_2") }}</li>
            </ol>
          </div>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          variant="primary"
          pill
          @click="close()"
          :disabled="ocrLoading"
        >
          {{ $t("common.understood") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-saving"
      size="sm"
      hide-footer
      centered
      no-close-on-backdrop
      hide-header-close
      scrollable
      :title="$t('common.wait')"
      header-class="justify-content-center"
    >
      <p class="text-center"><b-spinner variant="primary" /></p>
      <p class="text-center">{{ $t("common.saving_info") }}</p>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { VBToggle } from "bootstrap-vue";
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { VBModal } from "bootstrap-vue";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import { useDomain } from "@state/domain/domain";
import { useCompanyForm } from "@state/company/form";
import { useChangeable } from "@state/behaviours/changeable";

import ChangeableStatus from "@components/changeable/Status.vue";
import { ExclamationIcon, ClockIcon } from "@vue-hero-icons/outline";

import Basic from "./admissional/01Basic.vue";
import Ctps from "./admissional/02Ctps.vue";
import Voter from "./admissional/03Voter.vue";
import ArmedForces from "./admissional/04ArmedForces.vue";
import Cnh from "./admissional/05Cnh.vue";
import Bank from "./admissional/06Bank.vue";
import Dependents from "./admissional/07Dependents.vue";
import Epi from "./admissional/08Epi.vue";
import Benefits from "./admissional/09Benefits.vue";
import Cnpj from "./admissional/10Cnpj.vue";
import Contacts from "./admissional/11Contacts.vue";
import Forms from "./admissional/12Forms.vue";

function unloadFunction(e) {
  /**
   * @todo VERIFY NO STATE SE EXISTEM MUDANÇAS A SEREM SALVAS
   * Verificar o campo com foco, pegar valor e comprar com o state, se diferente, retorno abaixo:
   */
  let state_is_dirty = true;

  if (state_is_dirty) {
      var confirmationMessage = "o/";
      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
  }
  return false;
}

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ...FormComponents,
    ChangeableStatus,
    Basic,
    Ctps,
    Voter,
    ArmedForces,
    Cnh,
    Bank,
    Dependents,
    Epi,
    Benefits,
    Cnpj,
    Contacts,
    Forms,
    ExclamationIcon,
    ClockIcon,
  },
  props: {
    candidate_id: {
      required: true,
    },
    selective_process_id: {
      required: true,
    },
  },
  setup() {
    const formState = useCompanyForm();
    const curriculum = useCurriculum();
    const domain = useDomain();
    const changeable = useChangeable();

    return {
      // you can return the whole store instance to use it in the template
      formState,
      curriculum,
      domain,
      changeable,
      progress: curriculum.progress,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: true,
      changeCounter: 0,
      isSaving: false,
      emittedByZipCodeField: false,
      stateSubscribe: null,
      showStatus: false,
      ocrErrorFields: [],
      ocrLoading: true,
      // @todo ARRUMAR ISSO AQUI né?
      collapses: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },
  computed: {
    formTitle() {
      if (this.$route.path === "/profile/complementary-data") {
        return this.$t("admission_data.title");
      } else {
        return this.$t("admissional.title");
      }
    },
    formSections() {
      return _.pickBy(this.formState.admissional.sections, "show");
    },
    collapaseBaseId() {
      return "accordion-" + this._uid;
    },
    collapseBindings() {
      return {
        accordion: "accordion-" + this._uid,
      };
    },
    sectionProgress(name) {
      return (name) => {
        if (_.has(this.progress.admissional, name)) {
          return this.progress.admissional[name];
        } else {
          return {
            max: 100,
            current: 0,
          };
        }
      };
    }
  },
  created() {
    this.unloadFunction = _.bind(unloadFunction, this);

    window.addEventListener("beforeunload", this.unloadFunction);

    // if (_.isEmpty(this.$auth.user().cpf)) {
    //   this.$router.push({
    //     name: "complete-registration",
    //   });
    // } else if (this.$auth.user().need_to_change_password) {
    //   this.$router.push({
    //     name: "register4",
    //     params: { change_password: true },
    //   });
    // } else {

        this.isLoading = true;
        this.$root.loading = true;

        this.formState.load("admissional").then(() => {
          this.curriculum
            .read()
            .then(() => {

              /**
               * Racial field is required only in admissional form
               */
              if (this.curriculum.diversity.racial == 'n') {
                this.curriculum.diversity.racial = null
              }

                /**
                 * Civil Status field is required only in admissional form
                 */
                if (this.curriculum.personal.civil_status == 'i') {
                    this.curriculum.personal.civil_status = null
                }

                /**
                 * Gender field is required only in admissional form
                 */
                if (this.curriculum.personal.gender == 'u') {
                    this.curriculum.personal.gender = null
                }

              this.curriculum.loadProgress("admissional").then(() => {
                this.isLoading = false;
              });
            })
            .finally(() => {
                this.$root.loading = false;
            });
        })
    // }
  },
  mounted() {
    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.changeCounter = poll_changes.counter;

      if (poll_changes.counter >= 1) {
        this.showStatus = true;
        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.curriculum
          .persist(poll_changes.changes)
          .then(({ data }) => {
            if (data.success) {
              this.changeable.cleanUpAfterSave();
              this.changeCounter = 0;
            }
            this.isSaving = false;
          })
          .catch(() => {
            this.changeable.cleanUpAfterSave();
          })
          .finally(() => {
            this.curriculum.loadProgress("admissional");
            setTimeout(() => {
              this.showStatus = false;
            }, 1200);
            this.isSaving = false;
          });
      }
    });
  },
  destroyed() {
    this.stateSubscribe();
  },
  methods: {
    validate(save) {
      return new Promise((resolve, reject) => {
        window.profileObs = this.$refs.profileRules;
        this.$refs.profileRules.validate().then((success) => {
          if (success) {
            if (save) {
              this.$service.curriculum.save(this.profile);
            }
            resolve(success);
          } else {
            this.scrollToError();
            reject(false);
          }
        });
      });
    },
    save(e) {
      this.$refs.profileRules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.isSaving = false;
          return;
        }

        const poll_changes = this.changeable.poolChanges("curriculum");
        this.changeCounter = poll_changes.counter;

        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.showStatus = true;
        //this.curriculum.persist(poll_changes.changes).then(({ data }) => {
          //if (data.success) {
            this.changeable.cleanUpAfterSave();
            this.changeCounter = 0;
          //}

          //this.$router.push({ name: "profile" });
          this.isSaving = false;
          setTimeout(() => {
            this.showStatus = false;
          }, 3000);
        //});
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },

    scrollToError() {
      for (var i in this.$refs.profileRules.observers) {
        var observer = this.$refs.profileRules.observers[i];

        var field = _.chain(observer.errors)
          .mapValues((item) => item.length > 0)
          .pickBy((item) => item)
          .keys()
          .head()
          .value();

        if (field) {
          this.collapses[i] = true;
          this.$nextTick(() => {
            let el = document.getElementsByName(field)[0];
            if (el) {
              window.setTimeout(() => {
                el.scrollIntoView({ block: "start", behavior: "smooth" });
              }, 500);
            }
          });
          break;
        }
      }
    },
    goToSection(name) {
      //setTimeout(() => document.getElementsByName(name)[0].scrollIntoView(), 100);
    },
    updateHasManySectionsProgress() {
      setTimeout(() => {
        this.curriculum.loadProgress("admissional");
      }, 1000);
    },
    validateFileWithOcr(field) {
      if (this.company.config.hiring_data_ocr) {
        this.ocrLoading = true;
        var self = this;  

        if(field == "rg")
        {
          var ocrData = 
          {
            name: this.curriculum.personal.name,
            rgNumber: this.curriculum.personal.rg,
            birthDate:  this.curriculum.personal.birth_date
              ? this.curriculum.personal.birth_date.split('-').reverse().join('/')
              : null,
            dateOfIssue: this.curriculum.personal.date_of_issue_rg
              ? this.curriculum.personal.date_of_issue_rg.split('-').reverse().join('/')
              : null,
            cpf: this.curriculum.personal.cpf,
            fatherName: this.curriculum.personal.father_name,
            motherName: this.curriculum.personal.mother_name,
            city: this.curriculum.personal.city_rg,
            state: this.curriculum.personal.state_rg,
          };

        }
        else if(field == "cnh")
        {
          var ocrData = 
          {
            name: this.curriculum.personal.name,
            rgNumber: this.curriculum.personal.rg,
            cpf: this.curriculum.personal.cpf,
            birthDate:  this.curriculum.personal.birth_date
              ? this.curriculum.personal.birth_date.split('-').reverse().join('/')
              : null,
            cnhType: this.curriculum.cnh.cnh_type,
            cnhNumber: this.curriculum.cnh.cnh_number,
            expirationDate: this.curriculum.cnh.cnh_date_validity
              ? this.curriculum.cnh.cnh_date_validity.split('-').reverse().join('/')
              : null, 
            firstIssue: this.curriculum.cnh.cnh_date_first_emission
              ? this.curriculum.cnh.cnh_date_first_emission.split('-').reverse().join('/')
              : null,
            dateOfIssue: this.curriculum.cnh.cnh_date_emission
              ? this.curriculum.cnh.cnh_date_emission.split('-').reverse().join('/')
              : null,
          };
        }
        else if(field == "ctps")
        {
          var ocrData = 
          {
            ctpsNumber: this.curriculum.ctps.ctps_number,
            ctpsSerie: this.curriculum.ctps.ctps_serial
          };
        }
        else if(field == "pis")
        {
          var ocrData = 
          {
            pisNumber: this.curriculum.ctps.pis_pasep_number
          };
        }
        else if(field == "reservist")
        {
          var ocrData = 
          {
            name: this.curriculum.personal.name,
            birthDate:  this.curriculum.personal.birth_date
              ? this.curriculum.personal.birth_date.split('-').reverse().join('/')
              : null,
            reservistRA: this.curriculum.armed_forces.certificate_of_armed_forces_service,
            dateOfIssue: this.curriculum.armed_forces.certificate_of_armed_forces_service_date
              ? this.curriculum.armed_forces.certificate_of_armed_forces_service_date.split('-').reverse().join('/')
              : null,
            fatherName: this.curriculum.personal.father_name,
            motherName: this.curriculum.personal.mother_name
          };
        }
        else if(field == "voter")
        {
          var ocrData = 
          {
            name: this.curriculum.personal.name,
            birthDate:  this.curriculum.personal.birth_date
              ? this.curriculum.personal.birth_date.split('-').reverse().join('/')
              : null,
            voterNumber: this.curriculum.voter.voter_registration_number,
            zone: this.curriculum.voter.voter_registration_zone,
            section: this.curriculum.voter.voter_registration_section,
            dateOfIssue: this.curriculum.voter.voter_registration_date
              ? this.curriculum.voter.voter_registration_date.split('-').reverse().join('/')
              : null
          };
        }
        else if(field == "cpf")
        {
          var ocrData = 
          {
            name: this.curriculum.personal.name,
            cpf: this.curriculum.personal.cpf,
            birthDate:  this.curriculum.personal.birth_date
              ? this.curriculum.personal.birth_date.split('-').reverse().join('/')
              : null
          };
        }
        else if(field == "bank")
        {
          var ocrData = 
          {
            name: this.curriculum.bank.bank_name,
            accountAgency: this.curriculum.bank.bank_agency,
            accountNumber: this.curriculum.bank.bank_account
          };
        }
        else if(field == "address")
        {

          var ocrData = 
          {
            name: this.curriculum.personal.name,
            address: this.curriculum.address.street,
            neighborhood: "",
            city: this.curriculum.address.city,
            cep: this.curriculum.address.zip_code
          };
        }
        else if(field == "birth")
        {

          var ocrData = 
          {
            name: this.curriculum.personal.name,
            cpf: this.curriculum.personal.cpf,
            fatherName: this.curriculum.personal.father_name,
            motherName: this.curriculum.personal.mother_name, 
            gender: this.curriculum.personal.gender

          };

        }
    
        this.$bvModal.show("modal-ocr");
        this.ocrErrorFields = [];

        setTimeout(() => {
          this.curriculum
            .validateFileWithOcr({
              field: field,
              data: ocrData
            })
            .then((response) => {
              if (response.data.success && !_.isEmpty(response.data.fields)) {

                response.data.fields.forEach((field) => {

                  if(field.matchPercentage < field.limitMatchPercentage)
                  {
                    this.ocrErrorFields.push(field.field);
                  }
                });

                this.ocrLoading = false;
              } else {
                this.$bvModal.hide("modal-ocr");
              }
            })
            .catch((error) => {
              this.$bvModal.hide("modal-ocr");
            })
            .finally(() => {

                if (this.ocrErrorFields.length > 0) {
                  this.ocrErrorFields.forEach((field) => {

                    self.$refs.profileInnerRules.setErrors({
                      ["curriculum." + field]: [this.$t('common.field_mismatch')],
                    });

                  });
                  
                }else
                {
                  this.$bvModal.hide("modal-ocr");
                }
              //@todo O código está horrível, um dia alguém arruma, boa sorte.
              //SÉRIO, ARRUMEM ISSO AQUI
              /*
              for (const ref of this.$refs["profileInnerRules"]) {
                const errors = {};
                for (const field of this.ocrErrorFields) {

                  console.error(field);


                  errors["curriculum." + field] = [
                    this.$t('common.field_mismatch')
                  ];
                }
                ref.setErrors(errors);
              }*/
            });
        }, 2000);
      }
    },
  },
  beforeRouteLeave(to, from, next) {

    if (to.name == "complete-registration" || to.name == "register4") {
      next();
    } else {
      this.$refs.profileRules.validate().then((success) => {
        if (!success) {
          const answer = window.confirm(
            this.$t('common.exit_warning')
          );

          if (answer) {
            next();
          } else {
            this.inputFocus();
            next(false);
          }
        } else {
          next();
        }
      });
    }
  },
  watch: {
    "curriculum.personal.rg_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(this.curriculum.personal.rg_file2_id) &&
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("rg");
        }
      },
      deep: true,
    },
    "curriculum.personal.rg_file2_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(this.curriculum.personal.rg_file_id) &&
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("rg");
        }
      },
      deep: true,
    },
    "curriculum.cnh.cnh_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("cnh");
        }
      },
      deep: true,
    },
    "curriculum.ctps.ctps_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("ctps");
        }
      },
      deep: true,
    },
    "curriculum.ctps.pis_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("pis");
        }
      },
      deep: true,
    },
    "curriculum.armed_forces.certificate_of_armed_forces_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("reservist");
        }
      },
      deep: true,
    },
    "curriculum.voter.voter_registration_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("voter");
        }
      },
      deep: true,
    },
    "curriculum.personal.cpf_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("cpf");
        }
      },
      deep: true,
    },
    "curriculum.bank.account_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("bank");
        }
      },
      deep: true,
    },
    "curriculum.address.address_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("address");
        }
      },
      deep: true,
    },
    "curriculum.personal.birth_file_id": {
      handler(newValue, oldValue) {
        if (
          !_.isEmpty(newValue) &&
          newValue !== oldValue &&
          newValue !== "REMOVED"
        ) {
          this.validateFileWithOcr("birth");
        }
      },
      deep: true,
    },
    "curriculum.transports": {
      deep: true,
      handler: "updateHasManySectionsProgress",
    },
    "curriculum.dependents": {
      deep: true,
      handler: "updateHasManySectionsProgress",
    },
    "profile.training": {
      handler(newValue, oldValue) {
        this.$forceUpdate();
      },
    },
    "curriculum.address.zip_code": {
      handler(zipcode, oldValue) {
        if (this.isLoading) {
          return;
        }

        this.emittedByZipCodeField = true;

        this.curriculum
          .setAddressBasedOnZipcode(zipcode)
          .then((response) => {
            if (response) {
              this.curriculum.address.street_type = response.street_type;
              this.curriculum.address.street = response.street;
              this.curriculum.address.district = response.district;
              this.curriculum.address.country = response.country;
              this.curriculum.address.state = response.state;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = response.city;

                this.curriculum.persist({
                  address: {
                    street_type: response.street_type,
                    street: response.street,
                    district: response.district,
                    country: response.country,
                    state: response.state,
                    city: response.city,
                    latitude: response.latitude,
                    longitude: response.longitude,
                  },
                });
              });
            } else {
              this.$refs.profileInnerRules.setErrors({
                "curriculum.address.zip_code": [
                  this.$t("errors.curriculum.invalid_zip_format")
                ],
              });

              this.curriculum.address.street_type = null;
              this.curriculum.address.street = null;
              this.curriculum.address.district = null;
              this.curriculum.address.country = null;
              this.curriculum.address.state = null;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = null;
              });
            }
          })
          .finally(() => (this.emittedByZipCodeField = false));
      },
    },
    "curriculum.address.state": function (uf) {
      if (!this.emittedByZipCodeField && !this.isLoading) {
        this.curriculum.address.city = "";
      }
      this.domain.load(`cities/${uf}`, "cities");
    },
    "curriculum.personal.state_rg": function (uf) {
      this.domain.load(`cities/${uf}`, "cities", "cities_rg");
    },
    "curriculum.personal.born_state": function (uf) {
      this.domain.load(`cities/${uf}`, "cities", "cities_born");
    },
    "curriculum.voter.voter_registration_state": function (uf) {
      this.domain.load(`cities/${uf}`, "cities", "cities_voter_registration");
    },
    "curriculum.bank.has_bank": function (value) {
      if (!value) {
        this.curriculum.bank.bank_name = null;
        this.curriculum.bank.bank_type = null;
        this.curriculum.bank.bank_holder_name = null;
        this.curriculum.bank.bank_holder_type = null;
        this.curriculum.bank.bank_agency = null;
        this.curriculum.bank.bank_account = null;
        this.curriculum.bank.bank_operation = null;
        this.curriculum.bank.account_file_id = null;
        this.curriculum.bank.account_file2_id = null;
      }
    },
    "curriculum.personal.has_mother": function (value) {
      // When checkbox is checked
      if (value === false) {
        this.formState.admissional.personal.mother_name.disabled = true;
        this.curriculum.personal.mother_name = "";
        this.changeable.placeChange(
          "curriculum.personal.mother_name",
          this.curriculum.personal.mother_name
        );
      } else {
        this.formState.admissional.personal.mother_name.disabled = false;
      }
    },
    "curriculum.personal.has_father": function (value) {
      // When checkbox is checked
      if (value === false) {
        this.formState.admissional.personal.father_name.disabled = true;
        this.curriculum.personal.father_name = "";
        this.changeable.placeChange(
          "curriculum.personal.father_name",
          this.curriculum.personal.father_name
        );
      } else {
        this.formState.admissional.personal.father_name.disabled = false;
      }
    },
    "curriculum.bank.bank_name": function (value) {
    // Applied dynamic validations based on bank_name
    if (this.company.config.flags.admissional_bank_account_validation) {

      const default_option = 20;
      const bank_account_options = {
        'Itaú': 6,
        'Bradesco': 8,
        'Santander': 9
      }

      // Get selected bank
      const selected = bank_account_options[value] || default_option;

      // Apply validation
      this.formState.admissional.bank.bank_account.rules = {numeric: true, max: selected}
    }
    },
    "curriculum.ctps.ctps_state": function (uf) {      
      this.domain.load(`cities/${uf}`, "cities");
    }
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
